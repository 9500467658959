import p1 from './image/projects-image/Bildschirmfoto am 2023-01-28 um 15.48.08-fullpage.webp';
import p3 from './image/projects-image/wrp-web-2.webp';
import p4 from './image/projects-image/Bildschirmfoto am 2023-01-28 um 16.05.24.webp';
import p5 from './image/projects-image/pingo.png';
import p6 from './image/projects-image/Xhost-icon.svg';
import p7 from './image/jap-logo.png';
import p8 from './image/projects-image/jap.jpg';
import p9 from './image/projects-image/copper.jpg';
import p10 from './image/projects-image/copper-logo.png';
import p11 from './image/projects-image/xhost-itim.jpeg';
import p12 from './image/projects-image/pingojs.webp';

function Projects() {
    return (
        <div>
        <div className='projects-content'>
        <div className='projects-box'>
        <div className='projects-img'><img src={p11} alt="copper" /></div>
        <div className='content-space'>
        <div className="projects-icon"><img style={{width: "50px",backgroundColor: "#ffff"}} src={p6} alt="" /></div>
        <div className='projects-title'>Xhost.itim</div>
        <div className="projects-text"><p>IT Infrastructure Management</p></div>
        <div className='projects-date'><p>24.07.2024</p></div>
        <div style={{color:"#453cd6"}}></div>
        </div></div></div>

        <div className='projects-content'>
        <div className='projects-box'>
        <div className='projects-img'><img src={p9} alt="copper" /></div>
        <div className='content-space'>
        <div className="projects-icon"><img style={{width: "50px",backgroundColor: "#ffff"}} src={p10} alt="" /></div>
        <div className='projects-title'>Copper</div>
        <div className="projects-text"><p>Copper is a lightweight front-end framework designed for simplicity and efficiency in modern web development.</p></div>
        <div className='projects-date'><p>04.05.2024</p></div>
        <div style={{color:"#453cd6"}}><a className='more' target="_blank" rel="noopener noreferrer"  href="https://github.com/philipstuessel/copper">GitHub</a><i className="bi bi-arrow-right-short"></i></div>
        </div></div></div>

        <div className='projects-content'>
        <div className='projects-box'>
        <div className='projects-img'><img src={p12} alt="pingojs" /></div>
        <div className='content-space'>
        <div className="projects-icon"><img style={{width: "50px",backgroundColor: "#ffff"}} src={p5} alt="" /></div>
        <div className='projects-title'>PingoJS </div>
        <div className="projects-text"><p>Efficient CMS with self-developed compiler and editor for user-defined content control, optimized for smooth website management.</p></div>
        <div className='projects-date'><p>26.08.2023</p></div>
        <div style={{color:"#453cd6"}}><a className='more' target="_blank" rel="noopener noreferrer"  href="https://pingojs.de">Website</a><i className="bi bi-arrow-right-short"></i></div>
        </div></div></div>

        <div className='projects-content'>
        <div className='projects-box'>
        <div className='projects-img'><img src={p8} alt='JAP'/></div>
        <div className='content-space'>
        <div className="projects-icon"><img style={{width: "80px", height: "80px"}} src={p7} alt="" /></div>
        <div className='projects-title'>JAP</div>
        <div className="projects-text"><p>When you install the zsh plugin, it introduces numerous useful aliases and adds new commands to your system.</p></div>
        <div className='projects-date'><p>17.08.2023</p></div>
        <div style={{color:"#453cd6"}}><a className='more' target="_blank" rel="noopener noreferrer"  href="https://japzsh.com">Website</a><i className="bi bi-arrow-right-short"></i></div>
        </div></div></div>
        
        <div className='projects-content'>
        <div className='projects-box'>
        <div className='projects-img'><img src={p1} alt='world search'/></div>
        <div className='content-space'>
        <div className="projects-icon"><img style={{width: "50px",backgroundColor: "#ffff"}} src="https://addons.mozilla.org/user-media/addon_icons/2780/2780419-64.png?modified=ad84b500&1674920050779" alt="" /></div>
        <div className='projects-title'>World Search</div>
        <div className="projects-text"><p>World Search is actually structured like the new tab. You can search with 11 searches or add unlimited shortcuts.</p></div>
        <div className='projects-date'><p>19.12.2022</p></div>
        <div style={{color:"#453cd6"}}><a className='more' target="_blank" rel="noopener noreferrer"  href="https://github.com/philiphoney/world-search">GitHub</a><i className="bi bi-arrow-right-short"></i></div>
        </div></div></div>
    
        <div className='projects-content'>
        <div className='projects-box'>
        <div className='projects-img'><img src={p3} alt='wrp'/></div>
        <div className='content-space'>
        <div className="projects-icon"><img style={{width: "50px",backgroundColor: "#ffff"}} src="https://worldresourcepack.com/favicon.ico" alt="" /></div>
        <div className='projects-title'>WRP Website</div>
        <div className="projects-text"><p>World Resource Pack website very nice design. Or with a contact form and much more..</p></div>
        <div className='projects-date'><p>26.01.2022</p></div>
        <div style={{color:"#453cd6"}}><a className='more' target="_blank" rel="noopener noreferrer"  href="https://worldresourcepack.com/">Website</a><i className="bi bi-arrow-right-short"></i></div>
        </div></div></div>

        <div className='projects-content'>
        <div className='projects-box'>
        <div className='projects-img'><img src={p4} alt='developerweb'/></div>
        <div className='content-space'>
        <div className="projects-icon"><img style={{width: "50px",backgroundColor: "#ffff"}} src="https://developerweb-online.netlify.app/Developer-web/Image/DW-logo-new.webp" alt="developerweb" /></div>
        <div className='projects-title'>Developer Web</div>
        <div className="projects-text"><p>The developer web is a tutorial that I gradually built up.</p></div>
        <div className='projects-date'><p>24.01.2022</p></div>
        <div style={{color:"#453cd6"}}><a className='more' target="_blank" rel="noopener noreferrer" href="https://developerweb-online.netlify.app">Website</a><i className="bi bi-arrow-right-short"></i></div>
        </div></div></div>
        </div>
    );
}
export default Projects       